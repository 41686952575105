const script = (locale) => {
  const code = 'gsk/profile-rx-' + locale + '/prod'
  return `
  (function(a,b,c,d){
    a='//tags.tiqcdn.com/utag/${code}/utag.js';
    b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
    a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
  })();

  jQuery(document).on('analytics-pageview', function() {
    utag.view(utag.data, {
      "tealium_event": 'view',
      "event_type": 'tracking'
    });
  });

  `
}

function renderClient (ctx) {
  // Abort if we already added script to head
  const tealiumScript = document.getElementById('tealium')
  if (tealiumScript) {
    return
  }

  // // Remove any empty tracking codes
  // const locale = _.get(ctx.store, 'getters.locales/current')

  // // Abort if no code
  // if (!locale) {
  //   console.log('No Tealium locale founded')
  //   return
  // }

  // Add script tag to head
  const comment = document.createComment('Loading script asynchronously')
  const scriptEl = document.createElement('script')
  scriptEl.id = 'tealium'
  scriptEl.type = 'text/javascript'
  scriptEl.append(script('pa'))

  document.body.prepend(scriptEl)
  document.body.prepend(comment)
}

function renderServer (ctx) {
  ctx.beforeNuxtRender(() => {
    // Remove any empty tracking codes
    // const locale = _.get(ctx.store, 'getters.locales/current')

    // // Abort if no code
    // if (!locale) {
    //   console.log('No Tealium locale founded')
    //   return
    // }

    ctx.app.head.__dangerouslyDisableSanitizers = _.union(
      _.get(ctx.app.head, '__dangerouslyDisableSanitizers', []),
      ['script']
    )
    ctx.app.head.script.push({
      id: 'tealium',
      hid: 'tealium',
      type: 'text/javascript',
      pbody: true,
      innerHTML: script('pa')
    })
  })
}

function startPageTracking (ctx) {
  ctx.app.router.afterEach((to) => {
    setTimeout(() => {
      utag.view(utag.data, {
        tealium_event: 'view',
        event_type: 'tracking'
      })
    }, 250)
  })
}

export default (ctx, inject) => {
  // Abort if in Dev mode, but inject dummy functions so $gtag events don't throw errors
  if (ctx.isDev) {
    // console.log("No Tealium tracking because your are in Dev mode")
    return
  }

  process.client ? renderClient(ctx) : renderServer(ctx)
  // if (process.client) { startPageTracking(ctx); }
}
