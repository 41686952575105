import Vue from 'vue'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

export default ({ app }, inject) => {
  // Set the function directly on the context.app object

  tippy.setDefaultProps({
    theme: 'light',
    allowHTML: true,
    trigger: 'mouseenter focus click',
    onCreate(instance) {
      instance.popper.setAttribute('class', 'tippy')
    }
  });

  Vue.set(Vue.prototype, 'tippy', tippy);
}