import localeDomains from '~/config/locale-domains'

export const state = () => (
  {
    data: [],
    localeDomains,
    current: {}
  }
)

export const getters = {
  data: (state) => {
    return state.data
  },
  current: (state, getters, rootState, rootGetters) => {
    const aux = _.words(state.current.code)

    return _.size(aux) > 1 ? aux[1] : aux[0]
  },
  currentDomain: (state, getters, rootState, rootGetters) => {
    return _.get(state.localeDomains, _.get(state, 'current.code'))
  }
}

export const mutations = {
  data (state, payload) {
    state.data = payload
  },
  current (state, payload) {
    state.current = payload
  }
}

export const actions = {
}
