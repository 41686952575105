module.exports = {
  differentDomains: process.env.DIFFERENT_DOMAINS === 'true',
  es: process.env.DOMAIN_ES || '',
  pa: process.env.DOMAIN_PA || '',
  cr: process.env.DOMAIN_CR || '',
  sv: process.env.DOMAIN_SV || '',
  hn: process.env.DOMAIN_HN || '',
  gt: process.env.DOMAIN_GT || '',
  do: process.env.DOMAIN_DO || '',
  en: process.env.DOMAIN_EN || '',
  jm: process.env.DOMAIN_JM || '',
  tt: process.env.DOMAIN_TT || ''
}
