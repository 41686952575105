
export default {
  name: 'SectionProfesionalSalud',
  data () {
    return {}
  },
  computed: {
    profesionalLink () {
      return `https://gskpro.com/${this.$root.$i18n.locale}/`
    }
  }
}
