export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":["es","en"]},
  vueI18nLoader: false,
  locales: [{"name":"Español","code":"es","iso":"es","file":"es.js","domain":""},{"name":"Panamá","code":"es-pa","iso":"es-PA","file":"es-PA.js","domain":""},{"name":"Costa Rica","code":"es-cr","iso":"es-CR","file":"es-CR.js","domain":""},{"name":"El Salvador","code":"es-sv","iso":"es-SV","file":"es-SV.js","domain":""},{"name":"Honduras","code":"es-hn","iso":"es-HN","file":"es-HN.js","domain":""},{"name":"Guatemala","code":"es-gt","iso":"es-GT","file":"es-GT.js","domain":""},{"name":"República Dominicana","code":"es-do","iso":"es-DO","file":"es-DO.js","domain":""},{"name":"Jamaica","code":"en-jm","iso":"en-JM","file":"en-JM.js","domain":""},{"name":"English","code":"en","iso":"en","file":"en.js","domain":""}],
  defaultLocale: "es",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: true,
  normalizedLocales: [{"name":"Español","code":"es","iso":"es","file":"es.js","domain":""},{"name":"Panamá","code":"es-pa","iso":"es-PA","file":"es-PA.js","domain":""},{"name":"Costa Rica","code":"es-cr","iso":"es-CR","file":"es-CR.js","domain":""},{"name":"El Salvador","code":"es-sv","iso":"es-SV","file":"es-SV.js","domain":""},{"name":"Honduras","code":"es-hn","iso":"es-HN","file":"es-HN.js","domain":""},{"name":"Guatemala","code":"es-gt","iso":"es-GT","file":"es-GT.js","domain":""},{"name":"República Dominicana","code":"es-do","iso":"es-DO","file":"es-DO.js","domain":""},{"name":"Jamaica","code":"en-jm","iso":"en-JM","file":"en-JM.js","domain":""},{"name":"English","code":"en","iso":"en","file":"en.js","domain":""}],
  localeCodes: ["es","es-pa","es-cr","es-sv","es-hn","es-gt","es-do","en-jm","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "s",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "a",
  15: "n",
  16: "g",
  17: "/",
  18: "e",
  19: "s",
  20: ".",
  21: "j",
  22: "s",
  23: "\"",
  24: ",",
  25: "\"",
  26: "e",
  27: "s",
  28: "-",
  29: "P",
  30: "A",
  31: ".",
  32: "j",
  33: "s",
  34: "\"",
  35: ":",
  36: "\"",
  37: ".",
  38: ".",
  39: "/",
  40: "l",
  41: "a",
  42: "n",
  43: "g",
  44: "/",
  45: "e",
  46: "s",
  47: "-",
  48: "P",
  49: "A",
  50: ".",
  51: "j",
  52: "s",
  53: "\"",
  54: ",",
  55: "\"",
  56: "e",
  57: "s",
  58: "-",
  59: "C",
  60: "R",
  61: ".",
  62: "j",
  63: "s",
  64: "\"",
  65: ":",
  66: "\"",
  67: ".",
  68: ".",
  69: "/",
  70: "l",
  71: "a",
  72: "n",
  73: "g",
  74: "/",
  75: "e",
  76: "s",
  77: "-",
  78: "C",
  79: "R",
  80: ".",
  81: "j",
  82: "s",
  83: "\"",
  84: ",",
  85: "\"",
  86: "e",
  87: "s",
  88: "-",
  89: "S",
  90: "V",
  91: ".",
  92: "j",
  93: "s",
  94: "\"",
  95: ":",
  96: "\"",
  97: ".",
  98: ".",
  99: "/",
  100: "l",
  101: "a",
  102: "n",
  103: "g",
  104: "/",
  105: "e",
  106: "s",
  107: "-",
  108: "S",
  109: "V",
  110: ".",
  111: "j",
  112: "s",
  113: "\"",
  114: ",",
  115: "\"",
  116: "e",
  117: "s",
  118: "-",
  119: "H",
  120: "N",
  121: ".",
  122: "j",
  123: "s",
  124: "\"",
  125: ":",
  126: "\"",
  127: ".",
  128: ".",
  129: "/",
  130: "l",
  131: "a",
  132: "n",
  133: "g",
  134: "/",
  135: "e",
  136: "s",
  137: "-",
  138: "H",
  139: "N",
  140: ".",
  141: "j",
  142: "s",
  143: "\"",
  144: ",",
  145: "\"",
  146: "e",
  147: "s",
  148: "-",
  149: "G",
  150: "T",
  151: ".",
  152: "j",
  153: "s",
  154: "\"",
  155: ":",
  156: "\"",
  157: ".",
  158: ".",
  159: "/",
  160: "l",
  161: "a",
  162: "n",
  163: "g",
  164: "/",
  165: "e",
  166: "s",
  167: "-",
  168: "G",
  169: "T",
  170: ".",
  171: "j",
  172: "s",
  173: "\"",
  174: ",",
  175: "\"",
  176: "e",
  177: "s",
  178: "-",
  179: "D",
  180: "O",
  181: ".",
  182: "j",
  183: "s",
  184: "\"",
  185: ":",
  186: "\"",
  187: ".",
  188: ".",
  189: "/",
  190: "l",
  191: "a",
  192: "n",
  193: "g",
  194: "/",
  195: "e",
  196: "s",
  197: "-",
  198: "D",
  199: "O",
  200: ".",
  201: "j",
  202: "s",
  203: "\"",
  204: ",",
  205: "\"",
  206: "e",
  207: "n",
  208: "-",
  209: "J",
  210: "M",
  211: ".",
  212: "j",
  213: "s",
  214: "\"",
  215: ":",
  216: "\"",
  217: ".",
  218: ".",
  219: "/",
  220: "l",
  221: "a",
  222: "n",
  223: "g",
  224: "/",
  225: "e",
  226: "n",
  227: "-",
  228: "J",
  229: "M",
  230: ".",
  231: "j",
  232: "s",
  233: "\"",
  234: ",",
  235: "\"",
  236: "e",
  237: "n",
  238: ".",
  239: "j",
  240: "s",
  241: "\"",
  242: ":",
  243: "\"",
  244: ".",
  245: ".",
  246: "/",
  247: "l",
  248: "a",
  249: "n",
  250: "g",
  251: "/",
  252: "e",
  253: "n",
  254: ".",
  255: "j",
  256: "s",
  257: "\"",
  258: "}",
}

export const localeMessages = {
  'es.js': () => import('../../lang/es.js' /* webpackChunkName: "lang-es.js" */),
  'es-PA.js': () => import('../../lang/es-PA.js' /* webpackChunkName: "lang-es-PA.js" */),
  'es-CR.js': () => import('../../lang/es-CR.js' /* webpackChunkName: "lang-es-CR.js" */),
  'es-SV.js': () => import('../../lang/es-SV.js' /* webpackChunkName: "lang-es-SV.js" */),
  'es-HN.js': () => import('../../lang/es-HN.js' /* webpackChunkName: "lang-es-HN.js" */),
  'es-GT.js': () => import('../../lang/es-GT.js' /* webpackChunkName: "lang-es-GT.js" */),
  'es-DO.js': () => import('../../lang/es-DO.js' /* webpackChunkName: "lang-es-DO.js" */),
  'en-JM.js': () => import('../../lang/en-JM.js' /* webpackChunkName: "lang-en-JM.js" */),
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
}
