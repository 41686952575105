const localeDomains = require('~/config/locale-domains')

export const state = () => (
  {
    localeDomains,
    client: {
      ip: null
    }
  }
)

export const getters = {
  client: (state) => {
    return state.client
  }
}

export const mutations = {
  client (state, payload) {
    state.client = {
      ...state.client,
      ...payload
    }
  }
}

export const actions = {
  nuxtServerInit ({ state, commit, dispatch }, { req }) {
    const headers = (req && req.headers) ? Object.assign({}, req.headers) : {}
    const xForwardedFor = headers['x-forwarded-for']
    const xRealIp = headers['x-real-ip']
    commit('client', { ip: xForwardedFor || xRealIp || req.connection.remoteAddress || req.socket.remoteAddress })

    // await dispatch('geolocation/load', state.client.ip)
  }
}
