export default function ({ app, store }) {

  store.commit('locales/current', app.i18n.localeProperties)

  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
  }

  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    store.commit('locales/current', newLocale)
  }
}