
export default {
  name: 'TextView',
  props: {
    classes: {
      type: String,
      default: ''
    }
  }
}
